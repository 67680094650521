
























































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import firebase from "@/plugins/firebase";
import Add from "./Add.vue";
import Confirm from "@/components/Confirm.vue";

export default Vue.extend({
  metaInfo: () => ({
    title: "Tartışmalar"
  }),
  props: {
    course: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      search: "",
      loading: false,
      headers: [
        {
          text: "Başlık",
          align: "start",
          filterable: true,
          value: "title",
          width: "40%"
        },
        { text: "Oluşturan", value: "user.fullName" },
        { text: "Yanıt", value: "posts" },
        { text: "Tarih", value: "creationTime" },
        { text: "", value: "actions", sortable: false }
      ],
      items: [] as any,
      selectedItem: {},
      dialog: false,
      dialogContent: ""
    };
  },
  computed: {
    currentUser(): any {
      return this.$store.getters["auth/user"];
    },
    selectedItemProps(): any {
      if (this.dialogContent === "Add") {
        return {
          course: this.course
        };
      } else {
        return {};
      }
    }
  },
  methods: {
    async getItems() {
      this.loading = true;

      try {
        this.items = [] as any;

        // Get all courses
        const query = await firebase
          .firestore()
          .collection("discussions")
          .where("courseId", "==", this.course.id)
          .orderBy("creationTime", "desc")
          .get();
        query.forEach(async doc => {
          if (doc.exists) {
            const discus = doc.data();
            discus.id = doc.id;
            discus.updateTime =
              discus.updateTime != null
                ? new Date(discus.updateTime.seconds * 1000).toLocaleString(
                    "tr-TR"
                  )
                : discus.updateTime;
            discus.creationTime =
              discus.creationTime != null
                ? new Date(discus.creationTime.seconds * 1000).toLocaleString(
                    "tr-TR"
                  )
                : discus.creationTime;

            // Tartışmayı oluşturan kişinin bilgilerini al
            const qsUser = await firebase
              .firestore()
              .collection("users")
              .where("uid", "==", discus.userId)
              .get();

            const user = qsUser.docs[0].data();

            discus.user = {
              fullName: `${user.firstName} ${user.lastName}`,
              firstName: user.firstName,
              lastName: user.lastName,
              roles: user.roles
            };

            this.items.push(discus);
          }
        });

        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$notify({
          type: "error",
          text: "Tartışma listesi alınamadı."
        });
        console.log(err);
      }
    },
    openItem(item: any) {
      this.$router.push({
        name: "course-discussion",
        params: {
          id: item.id
        }
      });
    },
    async deleteItem(discussion: any) {
      const confirm: any = this.$refs.confirm;
      const confirmation = await confirm.open(
        "Emin misiniz?",
        "Bu işlem geri alınamaz. Tartışmayı silmek istediğinizden emin misiniz?",
        {
          color: "red"
        }
      );

      if (confirmation) {
        this.$notify({
          text: "Tartışma siliniyor. Lütfen bekleyin..."
        });

        try {
          // Yanıtları sil
          const qsPosts = await firebase
            .firestore()
            .collection("discussionPosts")
            .where("discussionId", "==", discussion.id)
            .get();

          qsPosts.forEach(async post => {
            await firebase
              .firestore()
              .collection("discussionPosts")
              .doc(post.id)
              .delete();
          });

          // Tartışamyı sil
          await firebase
            .firestore()
            .collection("discussions")
            .doc(discussion.id)
            .delete();

          this.$notify({
            type: "success",
            text: "Tartışma silindi."
          });

          this.getItems();
        } catch (err) {
          this.$notify({
            type: "error",
            text: "Tartışma silinemedi. Lütfen daha sonra tekrar deneyiniz."
          });
        }
      }
    },
    deletePermission(item: any) {
      if (this.currentUser.roles.indexOf("admin") > -1) {
        return true;
      } else {
        return item.userId === this.$store.getters["auth/user"].id;
      }
    }
  },
  mounted() {
    this.getItems();
  },
  components: {
    Add,
    Confirm
  }
});
